import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, Typography, Radio, RadioGroup,
  FormControlLabel, FormControl, FormLabel, Paper, CircularProgress,
  Box, Tabs, Tab, Select, MenuItem, InputLabel, ThemeProvider,
  createTheme, Snackbar, LinearProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CloudUpload as CloudUploadIcon, YouTube as YouTubeIcon, Movie as MovieIcon } from '@mui/icons-material';
import ActionSelector from './components/ActionSelector';
import VideoAnalysis from './components/VideoAnalysis';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0000',
    },
    secondary: {
      main: '#282828',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F9F9F9',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const API_BASE_URL = 'https://dlc.co.il/api/';

const App = () => {
  const [action, setAction] = useState(null);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [videoId, setVideoId] = useState('');
  const [uploadType, setUploadType] = useState('url');
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [status, setStatus] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [timestamps, setTimestamps] = useState('');
  const [trendsData, setTrendsData] = useState(null);
  const [videoTypes, setVideoTypes] = useState([]);
  const [selectedVideoType, setSelectedVideoType] = useState('');
  const [youtubeAuthorized, setYoutubeAuthorized] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [reelsStatus, setReelsStatus] = useState('');

  useEffect(() => {
    fetchVideoTypes();
    checkYoutubeAuth();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleYoutubeCallback(code);
    }
  }, []);

  const fetchVideoTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}video_types/`);
      setVideoTypes(response.data.video_types);
      setSelectedVideoType(response.data.video_types[0]);
    } catch (error) {
      handleApiError(error);
    }
  };

  const checkYoutubeAuth = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}check_youtube_auth/`);
      setYoutubeAuthorized(response.data.authorized);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleYoutubeAuth = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}get_youtube_auth_url/`);
      window.location.href = response.data.authorization_url;
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleYoutubeCallback = async (code) => {
    try {
      await axios.post(`${API_BASE_URL}youtube_callback/`, { code });
      setYoutubeAuthorized(true);
      setSnackbar({ open: true, message: 'YouTube authorization successful.', severity: 'success' });
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleActionSelect = useCallback((selectedAction) => {
    setAction(selectedAction);
  }, []);

  const handleFileChange = useCallback((e) => {
    setFile(e.target.files[0]);
  }, []);

  const handleUrlChange = useCallback((e) => {
    setUrl(e.target.value);
  }, []);

  const handleVideoIdChange = useCallback((e) => {
    setVideoId(e.target.value);
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleVideoTypeChange = useCallback((event) => {
    setSelectedVideoType(event.target.value);
  }, []);

  const handleGenerateContent = useCallback(async () => {
    try {
      setLoading(true);
      setStatus('Processing video...');
      setUploadProgress(0);
      const formData = new FormData();
      if (uploadType === 'url') {
        formData.append('url', url);
      } else {
        formData.append('file', file);
      }
      formData.append('video_type', selectedVideoType);
      console.log('Sending request to generate content', { uploadType, videoType: selectedVideoType });
      const response = await axios.post(`${API_BASE_URL}generate_all_content/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 3600000,  // 60 minutes timeout
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
          setStatus(`Uploading: ${percentCompleted}%`);
        }
      });

      console.log('Received response from server', response.data);

      if (response.data && response.data.content) {
        const content = response.data.content;
        console.log('Content received', content);

        if (typeof content.title !== 'string') {
          console.warn('Unexpected title type', typeof content.title);
          content.title = String(content.title);
        }
        if (typeof content.description !== 'string') {
          console.warn('Unexpected description type', typeof content.description);
          content.description = String(content.description);
        }
        if (!Array.isArray(content.tags)) {
          console.warn('Unexpected tags type', typeof content.tags);
          content.tags = content.tags ? String(content.tags).split(',') : [];
        }
        if (!Array.isArray(content.timestamps)) {
          console.warn('Unexpected timestamps type', typeof content.timestamps);
          content.timestamps = [];
        }

        setTitle(content.title || '');
        setDescription(content.description || '');
        setTags(content.tags.join(', '));
        setTimestamps(content.timestamps.map(ts => `${ts.time} - ${ts.title}`).join('\n'));

        if (response.data.trends_data && typeof response.data.trends_data === 'object') {
          setTrendsData(response.data.trends_data);
        } else {
          console.warn('Unexpected trends_data', response.data.trends_data);
          setTrendsData(null);
        }

        setStatus('Content generation completed.');
        setSnackbar({ open: true, message: 'Content generated successfully!', severity: 'success' });
      } else {
        console.warn('Received incomplete data from server', response.data);
        setStatus('Received incomplete data from server.');
        setSnackbar({ open: true, message: 'Received incomplete data from server.', severity: 'warning' });
      }
    } catch (error) {
      console.error('Error in handleGenerateContent', error);
      handleApiError(error);
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  }, [url, file, uploadType, selectedVideoType]);

  const handleGenerateReels = useCallback(async () => {
    try {
      setLoading(true);
      setReelsStatus('Generating Reels...');
      const formData = new FormData();
      if (uploadType === 'url') {
        formData.append('url', url);
      } else {
        formData.append('file', file);
      }
      const response = await axios.post(`${API_BASE_URL}generate_reels/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 3600000,  // 60 minutes timeout
      });
      setReelsStatus('Reels generation started. You will be notified when it\'s complete.');
      setSnackbar({ open: true, message: 'Reels generation started successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error in handleGenerateReels', error);
      setReelsStatus('Failed to generate Reels.');
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  }, [url, file, uploadType]);

  const handlePublish = useCallback(async () => {
    if (!youtubeAuthorized) {
      setSnackbar({ open: true, message: 'YouTube authorization required', severity: 'warning' });
      return;
    }
    if (!videoId) {
      setSnackbar({ open: true, message: 'Video ID is missing', severity: 'warning' });
      return;
    }
    try {
      setLoading(true);
      setStatus('Publishing content to YouTube...');
      const formData = new FormData();
      formData.append('video_id', videoId);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('tags', tags);
      formData.append('timestamps', timestamps);
      const response = await axios.post(`${API_BASE_URL}publish_content/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSnackbar({ open: true, message: 'Content published to YouTube successfully.', severity: 'success' });
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  }, [youtubeAuthorized, videoId, title, description, tags, timestamps]);

  const handleApiError = useCallback((error) => {
    setLoading(false);
    setUploadProgress(0);
    console.error('API Error', error);

    if (error.response) {
      console.error('Error response', error.response.data);
      if (error.response.status === 422) {
        setSnackbar({ open: true, message: 'Invalid input. Please check your data and try again.', severity: 'error' });
      } else if (error.response.status === 401) {
        setYoutubeAuthorized(false);
        handleYoutubeAuth();
      } else if (error.response.status === 429) {
        setSnackbar({ open: true, message: 'Too many requests. Please wait a moment and try again.', severity: 'error' });
      } else {
        setSnackbar({ open: true, message: `Error: ${error.response.data.detail || 'An unknown error occurred'}`, severity: 'error' });
      }
    } else if (error.request) {
      console.error('No response received', error.request);
      setSnackbar({ open: true, message: 'No response received from the server. Please try again later.', severity: 'error' });
    } else {
      console.error('Error setting up request', error.message);
      setSnackbar({ open: true, message: 'An unexpected error occurred. Please try again.', severity: 'error' });
    }
    setStatus('Error occurred');
  }, []);

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  }, [snackbar]);

  const renderTabsContent = useMemo(() => {
    switch (tabValue) {
      case 0:
        return (
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            multiline
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        );
      case 1:
        return (
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        );
      case 2:
        return (
          <TextField
            label="Tags"
            variant="outlined"
            fullWidth
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            helperText="Separate tags with commas"
          />
        );
      case 3:
        return (
          <TextField
            label="Timestamps"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={timestamps}
            onChange={(e) => setTimestamps(e.target.value)}
            helperText="Format: HH:MM:SS - Description"
          />
        );
      default:
        return null;
    }
  }, [tabValue, title, description, tags, timestamps]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h4" align="center" gutterBottom>
            <YouTubeIcon fontSize="large" style={{ color: theme.palette.primary.main }} /> YouTube Content Assistant
          </Typography>
          {!youtubeAuthorized && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<YouTubeIcon />}
              fullWidth
              onClick={handleYoutubeAuth}
              style={{ marginBottom: '20px' }}
            >
              Authorize YouTube
            </Button>
          )}
          {youtubeAuthorized && (
            <Typography variant="body1" align="center" style={{ marginBottom: '20px', color: 'green' }}>
              YouTube authorization successful
            </Typography>
          )}
          {!action && <ActionSelector onSelectAction={handleActionSelect} />}
          {action === 'add' && (
            <>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select video source</FormLabel>
                <RadioGroup row aria-label="uploadType" name="uploadType" value={uploadType} onChange={(e) => setUploadType(e.target.value)}>
                  <FormControlLabel value="url" control={<Radio />} label="Existing video (URL)" />
                  <FormControlLabel value="file" control={<Radio />} label="New video (Upload)" />
                </RadioGroup>
              </FormControl>
              {uploadType === 'url' ? (
                <TextField
                  label="Enter video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={url}
                  onChange={handleUrlChange}
                />
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                >
                  Upload Video
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              )}
              <FormControl fullWidth margin="normal">
                <InputLabel>Video Type</InputLabel>
                <Select
                  value={selectedVideoType}
                  onChange={handleVideoTypeChange}
                >
                  {videoTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                fullWidth
                onClick={handleGenerateContent}
                style={{ marginTop: '10px' }}
                disabled={loading}
              >
                Generate Content
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<MovieIcon />}
                fullWidth
                onClick={handleGenerateReels}
                style={{ marginTop: '10px' }}
                disabled={loading}
              >
                Generate Reels
              </Button>
              {loading && (
                <Box sx={{ width: '100%', marginTop: '10px' }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
              )}
              {status && (
                <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                  {status}
                </Typography>
              )}
              {reelsStatus && (
                <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                  {reelsStatus}
                </Typography>
              )}
              {title && (
                <Paper elevation={1} style={{ padding: '15px', marginTop: '20px' }}>
                  <Tabs value={tabValue} onChange={handleTabChange} centered>
                    <Tab label="Title" />
                    <Tab label="Description" />
                    <Tab label="Tags" />
                    <Tab label="Timestamps" />
                  </Tabs>
                  <Box p={3}>
                    {renderTabsContent}
                  </Box>
                  <TextField
                    label="Enter YouTube Video ID"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={videoId}
                    onChange={handleVideoIdChange}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<YouTubeIcon />}
                    fullWidth
                    onClick={handlePublish}
                    disabled={loading || !youtubeAuthorized}>
                    Publish to YouTube
                  </Button>
                </Paper>
              )}
              {trendsData && (
                <Paper elevation={1} style={{ padding: '15px', marginTop: '20px' }}>
                  <Typography variant="h6" gutterBottom>Trend Analysis</Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={Object.entries(trendsData.interest_over_time).map(([date, values]) => ({
                        date,
                        ...values
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {Object.keys(trendsData.interest_over_time[Object.keys(trendsData.interest_over_time)[0]]).map((key, index) => (
                        <Line
                          type="monotone"
                          dataKey={key}
                          stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`}
                          key={index}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </Paper>
              )}
            </>
          )}
          {action === 'analyze' && <VideoAnalysis apiBaseUrl={API_BASE_URL} />}
        </Paper>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default App;

