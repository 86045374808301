import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, CircularProgress } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = 'https://dlc.co.il/api';

const VideoAnalysis = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  const isValidYoutubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  };

  const handleAnalyze = async () => {
    if (!isValidYoutubeUrl(url)) {
      setError('Invalid YouTube URL. Please enter a valid YouTube video URL.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${API_BASE_URL}/analyze_video/`, { url });
      setResult(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError('Ошибка при анализе видео: ' + error.response.data.detail);
      } else if (error.response && error.response.status === 422) {
        setError('Ошибка 422: Проверьте правильность введенного URL');
      } else {
        setError('Ошибка при анализе видео: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Анализ видео
      </Typography>
      <TextField
        label="URL видео на YouTube"
        variant="outlined"
        fullWidth
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        margin="normal"
        error={!!error}
        helperText={error}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAnalyze}
        disabled={loading || !url}
      >
        Анализировать
      </Button>
      {loading && <CircularProgress style={{ marginTop: '20px' }} />}
      {result && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Результаты анализа:</Typography>
          <Typography><strong>Заголовок:</strong> {result.metadata.title}</Typography>
          <Typography><strong>Просмотры:</strong> {result.metadata.view_count}</Typography>
          <Typography><strong>Лайки:</strong> {result.metadata.like_count}</Typography>
          <Typography><strong>Комментарии:</strong> {result.metadata.comment_count}</Typography>
          <Typography><strong>Ключевые слова:</strong> {result.extracted_keywords.join(', ')}</Typography>
          <Typography variant="h6" style={{ marginTop: '10px' }}>Позиции в поиске:</Typography>
          {Object.entries(result.search_positions).map(([keyword, position]) => (
            <Typography key={keyword}>{keyword}: {position}</Typography>
          ))}
        </div>
      )}
    </Paper>
  );
};

export default VideoAnalysis;
