import React from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const ActionSelector = ({ onSelectAction }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Выберите действие
      </Typography>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button
          startIcon={<AddIcon />}
          onClick={() => onSelectAction('add')}
        >
          Добавить видео
        </Button>
        <Button
          startIcon={<AnalyticsIcon />}
          onClick={() => onSelectAction('analyze')}
        >
          Анализировать видео
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ActionSelector;
